<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <div class="page-content pb-0">
      <div class="card rounded-0 mb-0" data-card-height="cover-full">
        <!-- Top -->
        <div class="card-center text-center pt-4 mt-1">
          <h1 class="font-40 font-800 pb-3">
            Pas<span
              class="
                gradient-highlight
                p-2
                mx-1
                color-white
                scale-box
                d-inline-block
                rounded-s
                border-0
              "
              >JOB</span
            >
          </h1>
          <h5 class="mt-n3 font-16 font-600 opacity-30">
            Pencari Kerja dan Perusahaan
          </h5>
          <h5 class="pt-1 line-height-s font-400">
            {{ msg1 }}<br />
            {{ msg2 }}
          </h5>

          <div class="mt-3 mb-4">
            <img src="/images/success.svg" alt="" />
          </div>
          <router-link
            :to="{ name: 'Dashboard' }"
            class="
              btn btn-m btn-full
              ms-5
              me-5
              rounded-sm
              border-0
              gradient-highlight
              font-600 font-13
            "
          >
            Ke Halaman Akun
          </router-link>
        </div>
        <!-- Top -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template } from "@/components/menu/index.js";
export default {
  name: "EmailVerify",
  data() {
    return {
      isLoading: true,
      token: "",
      error: "",
      msg1: "",
      msg2: "",
    };
  },
  mounted() {
    init_template();
    this.verify();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    verify() {
      const token = this.$route.query.token;
      axios
        .get("/api/v1/auth/email-verify/?token=" + token)
        .then((response) => {
          this.msg1 = "Selamat!";
          this.msg2 = "Email Anda Sudah Berhasil Di verifikasi";
          console.log(response.data);
        })
        .catch((error) => {
          console.log("error", error.response);
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
          this.msg1 = "Maaf!";
          if (error.response.data) {
            this.msg2 = error.response.data["error"];
          }
        });
    },
  },
};
</script>
